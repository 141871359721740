import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Nav, Row, Col } from "react-bootstrap"
import { Modal, ModalBody, ModalFooter } from "reactstrap"

import { SocialIcon } from 'react-social-icons';
import { useEffect, useState} from "react"
import axios from "axios"
import settings from "../settings"
import imgLogo from "../images/iwant2explore-logo.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.scss"
import { CommonHeader } from "../pages/index"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const Menu = ({ zoneData }) => {
  const states = zoneData.states
  if (states && states.length === 0) {
    return <></>
  }
  return (
    <Col className="zone">
      <a href={"/" + zoneData.slug}>{zoneData.title}</a>
      <ul>
        {states &&
          states.map((state, i) => {
            return (
              <li key={"state_" + i}>
                <a href={"/"+state.slug}>{state.title}</a>
              </li>
            )
          })}
      </ul>
    </Col>
  )
}

const MainMenu = ({toggleMenu})=>{
  // console.log(SearchBanner)
  
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-8HP44X266E";
    script.async = true;
    // console.log('script')
    document.body.appendChild(script);
    const script1 = document.createElement('script');
    script1.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-8HP44X266E');`

    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1796965683166484";
    script2.async = true;
    document.body.appendChild(script2);
    

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    }
  }, []);
  return (<Nav
    activeKey="/home"
    className="justify-content-end menu"
    onSelect={selectedKey => {
    }}
  >
    {/* <SearchBanner></SearchBanner> */}
    <Nav.Item>
      <Nav.Link eventKey="itinerary" onClick={toggleMenu}>
        Explore
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href="/flight-and-hotels-booking" target="_blank">Book Hotels</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link target="_blank" href="https://kadakmerch.com/collections/visa2explore">Shop</Nav.Link>
    </Nav.Item>
    <Nav.Item>      
      <Nav.Link href="/support-us" target="_blank">Support us</Nav.Link>
    </Nav.Item>
  </Nav>)
}

const Header = ({showSearchBar, searchIconPos, faSearch, setShowSearchBar, toggleMenu, imgLogo}) => {
  return (
    <div id='headerId' className="header">
          <div className="section">
              <Row className="row">
                <Col xl={3} lg={2} md={2} sm={3} className="childrow">
                  <a href="/" className="logo">
                    <img src={imgLogo} alt="iwant2explore.com" />
                  </a>
                 </Col>
                 <Col xl={3} lg={3} md={2} sm={12} className="childrow search-wrapper-desktop">
                    {showSearchBar && <CommonHeader deviceType='mobile' />}
                    {searchIconPos && <span><FontAwesomeIcon icon={faSearch} onClick={() => {setShowSearchBar(!showSearchBar)}} /></span>}
                 </Col>
                <Col xl={6} lg={7} md={8} id="desktop-menu" className="childrow">
                   <MainMenu toggleMenu={toggleMenu}/>
                </Col>
              </Row>
          </div>
        </div>
  )
}

const MobileHeader = ({showSearchBar, searchIconPos, faSearch, setShowSearchBar, toggleMenu, mobileMenu, imgLogo}) => {
  return (
    <div id='headerId' className="mobile-header">
          <Row>
            <div className="col-4">
              <a href="/" className="logo">
                <img src={imgLogo} alt="iwant2explore.com" />
              </a>
            </div>
            <div className="search-wrapper-mobile col-6">
             {searchIconPos && <span><FontAwesomeIcon icon={faSearch} onClick={() => {setShowSearchBar(!showSearchBar)}} /></span>}
            </div>
            <div id="mobile-menu-btn" className="hamburger col-2" onClick={mobileMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            {showSearchBar && <CommonHeader deviceType='mobile' />}
          </Row>
            <div id="mobile-menu">
              <MainMenu toggleMenu={toggleMenu}/>
              <a
                href="javascript:void(0)"
                className="btn-close"
                onClick={mobileMenu}
              ></a>
            </div>
        </div>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query layoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMysqlZones {
        edges {
          node {
            title
            slug
            mysqlId
            states {
              title
              slug
              mysqlId
            }
          }
        }
      }
    }
  `)
  const [email, setEmail] = useState();
  const [searchIconPos, setSearchIconPos] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [desktopWindow, setDesktopWindow] = useState(false);
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const handleOpen = () => {
    const timer =  setTimeout(() => {
      setModal(true)
     }, 60000)
      return () => clearTimeout(timer);
  }
  useEffect(() => {
    handleOpen();
  }, [])

  const handleScroll = () => {
    const position = document.getElementsByClassName('banner')[0]?.getClientRects()[0]?.top
    if(position) {
      if(position < -200) {
        setSearchIconPos(true)
      } else {
        setSearchIconPos(false)
        setShowSearchBar(false)
      }
    }
  }

  useEffect(() => {
    setDesktopWindow(window.innerWidth > 768)
      const updateWidth = () => {
        setDesktopWindow(window.innerWidth > 768)
      };

      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }, []);

    useEffect(() => {
      const headerHeight = desktopWindow ?  document.getElementsByClassName('header')[0].offsetHeight : document.getElementsByClassName('mobile-header')[0].offsetHeight;
      document.getElementsByClassName('content-section')[0].style.paddingTop = headerHeight + 'px'
      document.getElementsByClassName('menu-details')[0].style.marginTop = headerHeight + 'px'
  }, [desktopWindow])

  useEffect(() => {
    const element = document.querySelectorAll('input[type="search"]');
    if (element.length == 0) { 
      setSearchIconPos(true)
    } else {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", closeMobileMenuOnScroll);
    return () => {
      window.removeEventListener("scroll", closeMobileMenuOnScroll);
    };
  }, [])

  function closeMobileMenuOnScroll() {
    const hamburger = document.querySelector(".hamburger");
    hamburger?.classList.remove("active");
    document.getElementById("mobile-menu")?.classList.remove("active");
    document.getElementsByClassName("menu-details")[0]?.classList.add("hidden");
    document.getElementById("resultListId")?.classList.add("hidden");
    document.getElementById("resultListDeskId")?.classList.add("hidden");
  }

  function mobileMenu() {
    const hamburger = document.querySelector(".hamburger");
    hamburger.classList.toggle("active");
    document.getElementById("mobile-menu").classList.toggle("active");
  }
  const toggleMenu = () => {
    if (
      document
        .querySelector(".menu-details")
        .getAttribute("class")
        .indexOf("hidden") > -1
    ) {
      document
        .querySelector(".menu-details")
        .setAttribute("class", "menu-details")
    } else {
      document
        .querySelector(".menu-details")
        .setAttribute("class", "menu-details hidden")
    }
  }

  const subscribe = (e)=>{
    e.preventDefault();
    axios({
      method: "post",
      url: settings.API_URL + "api/subscribe",
      data: {
        email: email,
      },
    })
      .then(data => {
        if (data.data.status === "success") {
          alert(
            "Thank you for subscribing to our Newsletter."
          )
          setEmail("")

        } else {
          throw "error"
        }
      })
      .catch(err => {
        alert(err)
      })
  }

  return (
    <>
      <div className="wrapper">
        {desktopWindow ? <Header showSearchBar={showSearchBar}
                                            searchIconPos={searchIconPos}
                                            faSearch={faSearch}
                                            setShowSearchBar={setShowSearchBar} 
                                            toggleMenu={toggleMenu} 
                                            imgLogo={imgLogo}
                                           /> : 
                                           <MobileHeader 
                                            mobileMenu={mobileMenu} 
                                            imgLogo={imgLogo}
                                            showSearchBar={showSearchBar}
                                            searchIconPos={searchIconPos}
                                            faSearch={faSearch}
                                            setShowSearchBar={setShowSearchBar} 
                                            toggleMenu={toggleMenu}
                                           />}                                     
        <div className="menu-details hidden">
          <div className="section">
            <Container fluid className="zones">
              <Row>
                {data &&
                  data.allMysqlZones &&
                  data.allMysqlZones.edges &&
                  data.allMysqlZones.edges.map((zone, i) => {
                    return <Menu zoneData={zone.node} key={"zone_" + i} />
                  })}
              </Row>
              <a
                href="javascript:void(0)"
                className="btn-close"
                onClick={toggleMenu}
              ></a>
            </Container>
          </div>
        </div>
        <div className="content-section">
          {children}
        </div>
        <div className="footer-subscribe">
          <div className="section">
            <h3>
              New video every sunday @ 9 am on Visa2Explore Youtube Channel.
            </h3>
            <a href="https://www.youtube.com/c/visa2explore/videos" target="_blank"><h1>Watch Now! </h1></a>
          </div>
        </div>
        <div className="footer">
          <div className="section">
              <Row>
                <Col lg={3} className="social">

                  <p>Connect with us @</p>
                  <ul className="social-icons">
                    <li><SocialIcon url="https://www.youtube.com/c/visa2explore" bgColor="#fff" target="_blank"/></li>
                    <li><SocialIcon url="https://www.facebook.com/visa2explore" bgColor="#fff"  target="_blank"/></li>
                    <li><SocialIcon url="https://twitter.com/baliharish1" bgColor="#fff"  target="_blank"/></li>
                  </ul>
                </Col>
                {/* <Col lg={5} className="subscribe">

                  <h4>For the most exclusive Journey Plans, Travelogues & Travel Tips </h4>
                  <h5>Subscribe Now to Iwant2Explore newsletter</h5>
                  <form onSubmit={subscribe}>
                    <Row>
                      <Col lg={6}>
                    <input type="email" value={email} placeholder="Email"  className="form-control" onChange={e => setEmail(e.currentTarget.value)}/>
                      </Col>
                      <Col>
                    <input type="submit" value="Subscribe" className="btn btn-secondary"
                           />
                      </Col>
                    </Row>
                  </form>

                </Col> */}
                <Col lg={4}>
                  <ul>
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/privacy-statement">Privacy Policy</a></li>
                    <li><a href="/terms-of-service">Terms and Conditions</a></li>
                    <li><a href="/refund-policy">Refund Policy</a></li>
                    <li><a href="/contact-us">Contact Us</a></li>
                  </ul>
                </Col>                
              </Row>
          </div>
          <Row className="copyright">
            <center>
              &copy; Visa2explore
            </center>
          </Row>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>              
                <ModalBody className="text-center border-0 modalbody">
                  <p>
                  If you have found our Itinerary useful, Do consider supporting us with your humble contribution.
                    </p> 
                    
                    <a className="aff_btn btn" href="/support-us">Support us</a>       
                </ModalBody>
                <ModalFooter className="modal-footer border-0">
                  <input
                    type="submit"
                    className="btn btn_primary modal-btn"
                    onClick={toggle}
                    value="Cancel"
                  />{" "}                  
                </ModalFooter>              
            </Modal>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
