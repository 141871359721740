import * as React from "react"

import { useEffect, useState, useRef } from "react"
import {Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import adventureImage from "../images/adventure.jpg"
import mountainImage from "../images/mountain.jpg"
import beachImage from "../images/beach.jpg"
import pilgrimageImage from "../images/badrinath.jpg"
import settings from "../settings"
import "../styles/index.scss"
import ScrollToTop from "react-scroll-to-top"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const CommonHeader = ({deviceType}) => {
  const headerId = deviceType === 'desktop' ? 'headerInputDesk' : 'headerInput'
  const resultListId = deviceType === 'desktop' ? 'resultListDeskId' : 'resultListId'
  const bannerClass = deviceType === 'desktop' ? 'banner' : 'show-header-banner'

  const [searchData, setSearchData] = useState({})
  const [filteredData, setFilteredData] = useState(null)
  const inputRef = useRef(null)

  useEffect(()=>{
    fetch("/searchdata/search.json").then(response=>response.json()).then((data)=>{
      setSearchData(data)
    }).catch((err)=>{
      alert(err)
    })
  }, [filteredData])

  useEffect(() => {
    const handleMouseUp = (event) => {
      if(event.target == document.getElementById(headerId)) {
        document.getElementById(resultListId)?.classList.remove("hidden")
      } else if(inputRef.current && !inputRef.current.contains(event.target)) 
        {
           inputRef.current.classList.add("hidden");
         }
    }

    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };

  }, [inputRef])

  const handleKeyup = (e) => {
    const term=e.currentTarget.value.toLowerCase()
      if(e.key === "Enter") {
        if(filteredData && redirectOnEnter.length > 0) {
          for(let item of redirectOnEnter) {
            if(item.title.toLowerCase().startsWith(term)) {
              navigate(`${item.url}`)
              return;
            }
          }
          navigate(`${redirectOnEnter[0].url}`)
        }
      } else {
        debounce(search, 500)(term)
      }
  }

  const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const search = (term)=>{
    if(term.length>1){
      const filteredItineraries = searchData.allMysqlItineraries.nodes.filter((value)=>{
        let searchPool =  value.title + " " + value.search_tags;
        return searchPool.toLowerCase().indexOf(term)>-1
      }).map((item)=>{
        return {title: item.title, url: `/${item.slug.toLowerCase()}` }
      })
      const filteredStates = searchData.allMysqlStates.nodes.filter((value)=>{
        let searchPool =  value.title + " " + value.search_tags;
        return searchPool.toLowerCase().indexOf(term)>-1
      }).map((item)=>{
        return {title: item.title, url: `/${item.slug.toLowerCase()}` }
      })
      const filteredCities = searchData.allMysqlCities .nodes.filter((value)=>{
        let searchPool =  value.title + " " + value.search_tags;
        return searchPool.toLowerCase().indexOf(term)>-1
      }).map((item)=>{
        return {title: item.title, url: `/${item.slug.toLowerCase()}` }
      })
      const filteredArticles = searchData.allMysqlArticles.nodes.filter((value)=>{
        const content = JSON.parse(value.content);
        let searchPool =  content.primaryInfo.title;
        return searchPool.toLowerCase().indexOf(term)>-1
      }).map((item)=>{
        const content = JSON.parse(item.content);
        return {title: content.primaryInfo.title, url: `/${item.slug.toLowerCase()}` }
      })


      setFilteredData({
        itineraries: filteredItineraries,
        cities :filteredCities,
        states: filteredStates,
        articles: filteredArticles,
      })
    }else{
      setFilteredData(null)
    }
  }

  const redirectOnEnter = []

  const ItemToDirectOnEnter = (redirectOnEnter, item) => {
    return redirectOnEnter.push(item)
  }

  return (
    <div className={bannerClass}>
      <div className="overlay">
        <div className="section">

          {deviceType === 'desktop' && <h1>Explore your next destination</h1>}
          {/* <div> */}
          <input type="search" id={headerId} placeholder="Try search for City, Location or Activity" onKeyUp={handleKeyup} />
          {deviceType === 'desktop' && <span><FontAwesomeIcon icon={faSearch} /></span>}
          
          {/* </div> */}
          {filteredData &&  (
            <div ref={inputRef} id={resultListId} className="resultList">

              {filteredData.itineraries.length ===0 &&
                filteredData.articles && filteredData.articles.length===0 &&
                filteredData.cities && filteredData.cities.length===0 &&
                filteredData.states && filteredData.states.length===0 && (
                  <p>Sorry the content your are requesting is not available</p>
                )
              }

                {filteredData.itineraries.length>0 && (
                  <div>
                  <p>Itineraries</p>
                    <ul>
                      {filteredData.itineraries.map((item)=>{
                        return (<li><a href={item.url}>{item.title}</a></li>)
                      })}
                    </ul>
                  </div>
                  )}
              {filteredData.articles && filteredData.articles.length>0 && (
                <div>
                  <p>Blog Posts</p>
                  <ul>
                    {filteredData.articles.map((item)=>{
                      {ItemToDirectOnEnter(redirectOnEnter, item)}
                      return (<li><a href={item.url}>{item.title}</a></li>)
                    })}
                  </ul>
                </div>
              )}
              {filteredData.cities && filteredData.cities.length>0 && (
                <div>
                  <p>Cities</p>
                  <ul>
                    {filteredData.cities.map((item)=>{
                      {ItemToDirectOnEnter(redirectOnEnter, item)}
                      return (<li><a href={item.url}>{item.title}</a></li>)
                    })}
                  </ul>
                </div>
              )}
              {filteredData.states && filteredData.states.length>0 && (
                <div>
                  <p>States</p>
                  <ul>
                    {filteredData.states.map((item)=>{
                      return (<li><a href={item.url}>{item.title}</a></li>)
                    })}
                  </ul>
                </div>
              )}
            </div>
          )
          }
          {deviceType === 'desktop' && <button className="btn" onClick={search}>Explore</button>}
        </div>
      </div>

    </div>
  )
}

const Card = ({backgroundImage, title, subtitle, url})=>{
  return (<div className="card" onClick={(e)=>{window.location.href=url}}>
    <div className="image" style={{backgroundImage: `url('${backgroundImage}')`}}>
      <div className="overlay">
        <p className="subtitle">{subtitle}</p>
      </div>
    </div>
    <p>{title}</p>

  </div>)

}

const BlogCard = ({backgroundImage, title, summary, url})=>{
  return (
    <div className="blogcard">
      <a href={url} alt={title}>
      <div className="image" style={{backgroundImage: `url('${backgroundImage}')`}}>
      </div>
        <h4>{title}</h4>

      </a>

    </div>
  )

}

const FeaturedDestination=({data})=>{
    return (
      <div className="featuredDestination">
        <div className="section">
          <h2>Featured Destinations</h2>
          <Row className="card-container">
            {
              data.map((iti)=>{
                const content = JSON.parse(iti.content);
                const thumbUrl = JSON.parse(content.primaryInfo.bannerImage).thumbUrl;
                if(iti.cities!==null){
                  return (<Card backgroundImage={settings.IMAGE_URL+thumbUrl+".webp"}
                                title={iti.title}
                                subtitle={iti.cities?.title}
                                url={`/${iti.slug.toLowerCase()}`}/>)
                }
                 return <div></div>
              })
            }
          </Row>
        </div>
      </div>
    )
}

const SeasonRecommendation=({data})=>{
  return (
    <div className="featuredDestination">
      <div className="section">
        <h2>Best Destinations for the season</h2>
        <Row className="card-container">
          {
            data.map((iti)=>{
              const content = JSON.parse(iti.content);
              const thumbUrl = JSON.parse(content.primaryInfo.bannerImage).thumbUrl;
              return (<Card backgroundImage={settings.IMAGE_URL+thumbUrl+".webp"}
                            title={iti.title}
                            subtitle={iti.cities?.title}
                            url={`/${iti.slug.toLowerCase()}`}/>)
            })
          }
        </Row>
      </div>
    </div>
  )
}

const PopularCollection = ({data})=>{
  const adventureCount = data.filter((i)=>(i.popular_collection==="adventure"))
  const mountainCount = data.filter((i)=>(i.popular_collection==="mountains"))
  const pilgrimageCount = data.filter((i)=>(i.popular_collection==="pilgrimage"))
  const beachesCount = data.filter((i)=>(i.popular_collection==="beaches"))

  return (
    <div  className="popularCollection">
      <div className="section">
        <h2>Popular Collections</h2>
        <Row className="card-container">
          <a href="/collection/adventure" className="card" style={{backgroundImage:`url('${adventureImage}')`}}>
              <div className="overlay">
              <h3>Adventure</h3>
              <p>{adventureCount.length>0?adventureCount[0].collection_count:"No"} Listings</p>
              </div>
          </a>
          <a href="/collection/mountains" className="card" style={{backgroundImage:`url('${mountainImage}')`}}>
              <div className="overlay">
              <h3>Mountains</h3>
                <p>{mountainCount.length>0?mountainCount[0].collection_count:"No"} Listings</p>
              </div>
          </a>
          <a href="/collection/beaches"  className="card" style={{backgroundImage:`url('${beachImage}')`}}>
              <div className="overlay">
              <h3>Beaches</h3>
                <p>{beachesCount.length>0?beachesCount[0].collection_count:"No"} Listings</p>
              </div>
          </a>
          <a href="/collection/pilgrimage" className="card" style={{backgroundImage:`url('${pilgrimageImage}')`}}>
              <div className="overlay">
              <h3>Pilgrimage</h3>
                <p>{pilgrimageCount.length>0?pilgrimageCount[0].collection_count:"No"} Listings</p>
              </div>
          </a>
        </Row>
      </div>
    </div>
  )
}

const BlogListing = ({data})=>{
  return (
    <div className="blog-listing">
      <div className="section">
        <h2>Blog</h2>
        <div className="card-container">
          {data.map((article)=>{
            const content = JSON.parse(article.content)
            const imageCandidate = content.pageContent.find((i)=>{
              return i.type==="IMAGE"
            }).images;
            let imageUrl="";
            if(imageCandidate.length>0){
              imageUrl = imageCandidate[0].imageData.publicUrl
            }
            return (
              <BlogCard
                backgroundImage={settings.IMAGE_URL+imageUrl+".webp"}
                title={content.primaryInfo.title}
                summary={"No Summary"}
                url={`/${article.slug.toLowerCase()}`}/>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const IndexPage = ({ data }) => {

    return (
  <Layout>
    <ScrollToTop smooth />
    <Seo title="Home" />
    <CommonHeader deviceType='desktop' />
    <FeaturedDestination data={data.allMysqlFeaturedItineraries.nodes}/>
    <PopularCollection data={data.allMysqlPopularCollectionCounts.nodes}/>
    <SeasonRecommendation  data={data.allMysqlSeasonItineraries.nodes}/>
    <BlogListing data={data.allMysqlArticles.nodes} />
  </Layout>)
}

export default IndexPage

//highlight-start
export const pageQuery = graphql`
 query MyQuery {
  allMysqlPopularCollectionCounts {
    nodes {
      popular_collection
      collection_count
    }
  }
  allMysqlFeaturedItineraries(limit: 4, sort: {order: DESC, fields: updatedAt}) {
    nodes {
      title
      slug
      content
      cities {
        title
      }
    }
  }
  allMysqlSeasonItineraries(limit: 8) {
    nodes {
      title
      slug
      content
      cities {
        title
      }
    }
  }
  allMysqlArticles(limit: 5, sort: {order: DESC, fields: updatedAt}) {
    nodes {
      content
      id
      slug
    }
  }
}
`
//highlight-end
